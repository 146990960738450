
import { PREPR_MODEL_NAMES } from '~/constants'

export default {
  props: {
    publication: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      styles: {
        minWidth: null,
        width: null,
      },
    }
  },
  computed: {
    cardProps () {
      return {
        to: this.$link.publication(this.publication),
        title: this.publication?.title,
        contentType: this.contentType,
        topic: this.publication?.topic,
        subtitle: this.subtitle,
        titleSize: this.titleSize,
        titleLines: this.titleLines,
        image: this.image,
        imageSize: this.imageSize,
        minHeight: this.minHeight,
        style: this.styles,
      }
    },
    contentType () {
      return this.publication?.type === 'Televisie' || this.publication?.featuredVideo?.length ? 'video' : null
    },
    id () {
      return this.publication?.reference?.id || this.publication.id
    },
    modelId () {
      return this.publication?.reference?.modelId
    },
    modelName () {
      return PREPR_MODEL_NAMES[this.modelId]
    },
    image () {
      return this.publication?.hero || this.publication?.heroUrl || this.publication?.imageUrl
    },
    imageSize () {
      return this.$attrs['image-size'] || 'xs:374 sm:369 md:372 lg:407'
    },
    isImageFull () {
      return this.$attrs['image-full'] || this.$attrs['image-full'] === ''
    },
    isSmall () {
      return this.$attrs.small || this.$attrs.small === '' || this.$attrs.tiny || this.$attrs.tiny === ''
    },
    minHeight () {
      return this.$attrs['min-height'] || (!this.isSmall ? '380px' : this.isImageFull ? '175px' : '100px')
    },
    subtitle () {
      return (this.isSmall || this.$attrs.subtitle) ? this.$attrs.subtitle || this.publication?.subtitle : null
    },
    titleSize () {
      return this.isSmall ? 'sm' : 'md'
    },
    titleLines () {
      return this.$attrs['title-lines'] || this.isSmall ? 2 : 3
    },
    topicIcon () {
      return this.publication?.topic?.icon
    },
    topicName () {
      return this.publication?.topic?.name
    },
    topicColor () {
      return this.isImageFull ? 'text--secondary' : 'text--primary'
    },
    likes () {
      return this.publication.likes || this.publication?.reference?.likes
    },
  },
}
